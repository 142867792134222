














































import _ from 'lodash';
import Vue from 'vue';
import { required, email, maxLength } from 'vuelidate/lib/validators';
import LogoCard from '@/components/specific/LogoCard.vue';
import getUserAttributesValue from '@/resources/functions/getUserAttributesValue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import settings from '@/settings';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';

const AuthService = ServiceFactory.get('auth');

export default Vue.extend({
  name: 'LoginPage',

  components: {
    LogoCard,
  },

  data() {
    return {
      loginParams: {
        email: '',
        password: '',
      },
      urlContact: settings.externalLinkURLs.loginIssue,
    };
  },
  methods: {
    ...DomainAuthMapper.mapActions(['setToken', 'setUserAttributes']),
    ...UICommonMapper.mapActions(['setNavigating', 'setErrorMessage']),
    async login() {
      const self = this;

      self.$v.$touch();

      if (self.$v.$invalid) {
        self.$$log.debug(self.$v);
        return;
      }

      self.setNavigating({ navigating: true });

      try {
        const response = await AuthService.login({
          email: self.loginParams.email,
          password: self.loginParams.password,
        });

        // トークンをストア
        self.setToken({
          // APIによりパラメータ名が定義されているため除外
          /* eslint-disable @typescript-eslint/camelcase */
          access_token: _.get(response, 'access_token'),
          id_token: _.get(response, 'id_token'),
          refresh_token: _.get(response, 'refresh_token'),
          /* eslint-enable @typescript-eslint/camelcase */
        });

        // ユーザー情報を取得、セット
        const responseAttr = await AuthService.getUserAttributes();
        const attr = {
          // APIによりパラメータ名が定義されているため除外
          /* eslint-disable @typescript-eslint/camelcase */
          company_name: getUserAttributesValue(responseAttr, 'custom:company_name'),
          email: getUserAttributesValue(responseAttr, 'email'),
          family_name: getUserAttributesValue(responseAttr, 'family_name'),
          family_name_kana: getUserAttributesValue(responseAttr, 'custom:family_name_kana'),
          given_name: getUserAttributesValue(responseAttr, 'given_name'),
          given_name_kana: getUserAttributesValue(responseAttr, 'custom:given_name_kana'),
          partner_id: getUserAttributesValue(responseAttr, 'custom:partner_id'),
          /* eslint-enable @typescript-eslint/camelcase */
        };
        self.setUserAttributes(attr);

        self.$router.push('/');
      } catch (error) {
        self.$$log.error(error);
        self.setNavigating({ navigating: false });
        self.setErrorMessage({ text: error.message });
      }
    },
  },
  validations() {
    return {
      loginParams: {
        email: {
          // エラーメッセージを表示したい順序で定義する
          /* eslint-disable vue/sort-keys */
          required,
          email,
          maxLength: maxLength(128),
          /* eslint-enable vue/sort-keys */
        },
        password: {
          // エラーメッセージを表示したい順序で定義する
          /* eslint-disable vue/sort-keys */
          required,
          containsNoTabs: this.$$validators.containsNoTabs,
          maxLength: maxLength(255),
          /* eslint-enable vue/sort-keys */
        },
      },
    };
  },
});
